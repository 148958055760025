import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    // bootstrap
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $("[data-toggle='toast']").toast("show");

    // missing translation
    if (console && !console.log) {
      $(".translation_missing").each(function () {
        console.log($(this).attr("title"));
      });
    }
  }
}
