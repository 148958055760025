import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "destination"]

  connect() {
    this.source = this.sourceTarget.innerHTML
    $(this.sourceTarget).remove()
  }

  show() {
    $(this.destinationTarget).html(this.source)
  }

  hide() {
    $(this.destinationTarget).empty()
  }
}
