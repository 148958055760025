import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["remaining", "timer"]

  connect() {
    var self = this;
    this.countdown(self);
    setInterval(function() { self.countdown(self); }, 1000);
  }

  countdown(controllerContext) {
    var remaining_seconds = controllerContext.remainingTarget.value;
    if(remaining_seconds > 0){
      if(remaining_seconds % 3 == 0){
        this.saveProgress();
      }
      var date = new Date(0);
      date.setSeconds(remaining_seconds);
      $(controllerContext.timerTarget).text(date.toISOString().substr(11, 8));
      remaining_seconds--;
      $(controllerContext.remainingTarget).val(remaining_seconds);
    }
    else if(remaining_seconds == 0){
      remaining_seconds--;
      $(controllerContext.remainingTarget).val(remaining_seconds);
      alert("The exam has ended.");
      $("form.card").trigger("submit");
    }
  }

  saveProgress(){
    var $form = $("form.card"), url = $('#examination_save_progress_url').val();
    $.post(url, $form.serialize());
  }
}
