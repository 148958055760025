import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldsContainer", "field", "template"]

  connect() {
    this.index = this.fieldTargets.length
    this.template = this.templateTarget.innerHTML
    $(this.templateTarget).remove()
  }

  add() {
    this.index += 1;
    $(this.template.replace(/999/g, this.index)).appendTo(this.fieldsContainerTarget)
  }

  addRandomQuestions() {
    var nr = $("#random_questions").val();
    for (var i = 0; i < nr; i++){
      this.add();
      var select = $("select.form-control").last()[0];
      var items = select.getElementsByTagName('option');
      var index = Math.floor(Math.random() * items.length);
      select.selectedIndex = index;
    }
  }

  remove(event) {
    $(event.target).parents(".inner").remove()
  }
}
