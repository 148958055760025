import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["video", "questions"];

  connect() {
    var self = this
    self.player = new Plyr(this.videoTarget, {
      controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "captions"],
      ratio: this.data.get("ratio"),
      captions: { active: true },
      listeners: { seek: self.customSeekBehavior }
    })
    self.userMode = $("#user_mode").length > 0

    self.questions = []

    self.set_events(self)
    self.set_caption_questions(self)
  }

  set_events(self){
    self.player.on('playing', event => {
      self.start_saving_progress(self)
      self.start_caption_questions(self)
    })

    self.player.on('pause', event => {
      self.stop_saving_progress()
      self.end_caption_questions()
    })

    self.player.on('ended', event => {
      self.stop_saving_progress()
      self.end_caption_questions()
    })

    self.set_video_progress(self)
  }

  set_video_progress(self){
    if(self.userMode){
      var last_progress = $("#last_progress").val()
      self.player.once('canplay', event => {
        self.player.currentTime = parseInt(last_progress)
      })
    }
  }

  start_saving_progress(self){
    if(self.userMode){
      this.stop_saving_progress()
      window.progressInterval = setInterval(function() { self.saveProgress() }, 3000)
    }
  }

  stop_saving_progress(){
    if(self.userMode){
      clearInterval(window.progressInterval)
    }
  }

  set_caption_questions(self){
    var captionsQuestionsUrl = $("#captions_questions_url").val()
    if(captionsQuestionsUrl){
      $.ajax({
        type: 'GET',
        url: captionsQuestionsUrl,
        success: function(data) {
          self.questions = data
        }
     })
    }
  }

  start_caption_questions(self){
    self.end_caption_questions()
    window.captionQuestionsInterval = setInterval(function() { self.showCaptionQuestions(self) }, 1000)
  }

  end_caption_questions(){
    clearInterval(window.captionQuestionsInterval)
  }

  customSeekBehavior(event){
    if($("#save_progress_url").length){
      var currentTime = this.currentTime
      var newTime = event.target.value / event.target.max * this.media.duration
      if(newTime > currentTime){
        event.preventDefault()
        return false
      }
    }
  }

  saveProgress(){
    var saveProgressUrl = $("#save_progress_url").val()
    if(saveProgressUrl){
      $.ajax({
        type: 'PUT',
        url: saveProgressUrl,
        data: {
          progress: this.player.currentTime,
          content_length: this.player.duration
        }
     })
    }
  }

  showCaptionQuestions(self){
    var caption = self.questions.find(el=>el.time_in_seconds == parseInt(self.player.currentTime))
    if(caption){
      var q = "<p>" + caption.question.question + "<p>"
      q += "<ul>"
      caption.question.answers.forEach(el=>{
        if(el.correct){
          q += "<li><b>" + el.answer + "</b></li>"
        }
        else{
          q += "<li>" + el.answer + "</li>"
        }
      })
      q += "</ul>"
      $(self.questionsTarget).html(q)
    }
  }
}
