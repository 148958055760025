import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  open() {
    const url = this.data.get("url");
    if (url == "") {
      return;
    }
    app.load(url);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
}
