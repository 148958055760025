import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [
    "previewWrapper", "previewUrl", "previewLink",
    "progressWrapper", "progressBar",
    "uploadWrapper", "uploadInput",
    "value"
  ]

  connect() {
    this.state = this.data.get("url") ? "uploaded" : "empty"
    this.updateState()
  }

  upload() {
    var file = this.uploadInputTarget.files[0]
    var direct_upload_url = $(this.uploadInputTarget).data("directUploadUrl")
    var uploader = new DirectUpload(file, direct_upload_url, this)
    var ctrl = this
    uploader.create(function (error, blob) { ctrl.uploadFinished(error, blob); })
    this.uploadInputTarget.value = null
    this.state = "uploading"
    this.updateState()
  }

  directUploadWillStoreFileWithXHR(request) {
    var ctrl = this
    request.upload.addEventListener("progress", function (event) { ctrl.uploadProgress(event) })
  }

  uploadProgress(data) {
    var progress = parseInt((data.loaded / data.total) * 100, 10)
    $(this.progressBarTarget).css("width", progress + "%")
  }

  uploadFinished(error, blob) {
    // TODO: handle errors
    this.data.set("filename", blob.filename)
    this.data.set("url", "/rails/active_storage/blobs/" + blob.signed_id + "/" + blob.filename)
    this.valueTarget.value = blob.signed_id
    this.state = "uploaded"
    this.updateState()

  }

  updateState() {
    $(this.previewWrapperTarget).addClass("d-none")
    $(this.uploadWrapperTarget).addClass("d-none")
    $(this.progressWrapperTarget).addClass("d-none")
    switch (this.state) {
      case "empty":
        $(this.uploadWrapperTarget).removeClass("d-none")
        break;
      case "uploaded":
        $(this.previewWrapperTarget).removeClass("d-none")
        break;
      case "uploading":
        $(this.progressWrapperTarget).removeClass("d-none")
        break;
    }
    if (this.data.get("url")) {
      var url = this.data.get("url");
      var filename = this.data.get("filename");
      $(this.previewUrlTarget).html(filename).prop("href", url);
      $(this.previewLinkTarget).prop("href", url);
    }
  }

  delete() {
    this.data.delete("url")
    this.data.set("filename", null)
    this.uploadInputTarget.value = ""
    this.valueTarget.value = ""
    $(this.progressBarTarget).css("width", "0%")
    this.state = "empty"
    this.updateState()
  }
}
